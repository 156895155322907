<template>
  <div>
    <div class="d-flex justify-content-between align-items-center p-0">
      <div class="col-auto">
        <span class="p-2">
          Facture Référence Nº :
          <span class="fw-bold">
            {{ $route.params.reference }}
          </span>
        </span>
      </div>
      <div class="col-auto row">
        <div
        v-if="$userRole == 'Admin'"
          
          class="col-auto p-1"
        >
          <button @click="refresh()" class="btn border">
            <i class="bi bi-check2-square text-secondary fs-4"></i> Valider
          </button>
        </div>

        <div
          v-if="payment.reference "
          class="col-auto p-1"
        >
          <button
          v-if="$userRole == 'Admin'"
            @click="
              $router.push({
                name: 'payments-customers-edit',
                params: { reference: payment.reference },
              })
            "
            class="btn border"
          >
            <i class="bi bi-pencil-square text-secondary fs-4"></i> Modifier
          </button>
        </div>
        <div v-if="payment.reference" class="col-auto p-1">
          <button @click="print(payment.reference)" class="btn border">
            <i class="bi bi-printer text-secondary fs-4"></i> Imprimer
          </button>
        </div>

        <div
          v-if="payment.reference && userRole == 'Admin'"
          class="col-auto p-1"
        >
          <button @click="remove(payment)" class="btn border">
            <i class="bi bi-trash text-secondary fs-4"></i> Supprimer
          </button>
        </div>
        <div class="col-auto p-1">
          <button @click="refresh()" class="btn border">
            <i class="bi bi-arrow-clockwise text-secondary fs-4"></i> Actualiser
          </button>
        </div>
      </div>
    </div>
    <hr />
    <div class="row align-items-stretch" id="printMe">
      <div class="col-6">
        <!-- customer name -->
        <div class="row justify-content-between">
          <div class="col p-1">Client</div>
          <div class="col p-1 fw-bold">
            <span
              v-if="payment.customer"
              :class="
                payment.customer.fullName.includes('Bloqué')
                  ? 'text-danger'
                  : ''
              "
              class="fw-bold"
            >
              {{ payment.customer.fullName }}
            </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Nº de Téléphone</div>
          <div class="col p-1 fw-bold">
            <span v-if="payment.customer" class="fw-bold">
              {{ payment.customer.phone }}
            </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Nom Commercial (Société)</div>
          <div class="col p-1 fw-bold">
            <span v-if="payment.customer" class="fw-bold">
              {{ payment.customer.society }}
            </span>
          </div>
        </div>
      </div>

      <!-- paiments -->
      <div class="col-6 justify-content-between">
        <div class="row">
          <div class="col p-1">Total Payé :</div>
          <div v-if="payment.amount" class="col p-1 fw-bold">
            {{ payment.amount | toFixedWithSpace }} Dh
          </div>
          <div v-else class="col p-1 fw-bold text-danger">----------</div>
        </div>

        <div class="row">
          <div class="col p-1">Montant en lettres :</div>
          <div
            v-if="payment.writtenAmount"
            class="col p-1 fw-bold text-uppercase"
          >
            {{ payment.writtenAmount }} Dh
          </div>
          <div v-else class="col p-1 fw-bold text-danger">----------</div>
        </div>

        <div class="row">
          <div class="col p-1">Montant Impayé :</div>
          <div v-if="payment.remainingAmount" class="col p-1 fw-bold">
            {{ payment.remainingAmount | toFixedWithSpace }}
          </div>
        </div>

        <div class="row">
          <div class="col p-1">Mode de paiement :</div>
          <div v-if="payment.paymentMethod" class="col p-1 fw-bold">
            {{ payment.paymentMethod }}
          </div>
        </div>

        <div class="row">
          <div class="col p-1">Nº de chéque :</div>
          <div v-if="payment.paymentMethod" class="col p-1 fw-bold">
            {{ payment.checkNumber }}
          </div>
        </div>

        <div class="row">
          <div class="col p-1">Nº de Virement :</div>
          <div v-if="payment.paymentMethod" class="col p-1 fw-bold">
            {{ payment.bankTransferNumber }}
          </div>
        </div>
      </div>
    </div>

    <br />
    <div class="row align-items-stretch">
      <div class="col-6">
        <div class="row justify-content-between">
          <div class="col p-1">Vérifié (oui/non) :</div>
          <div class="col p-1 fw-bold">
            <span v-if="payment.customer"> - </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Vérifié par :</div>
          <div class="col p-1 fw-bold">
            <span v-if="payment.customer"> - </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Vérifié à :</div>
          <div class="col p-1 fw-bold">
            <span v-if="payment.customer"> - </span>
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="row justify-content-between">
          <div class="col p-1">Validé (oui/non) :</div>
          <div class="col p-1 fw-bold">
            <span v-if="payment.validated"> Validee </span>
            <span v-else class="bg-warning rounded p-1"> En Attente </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Validé par :</div>
          <div class="col p-1 fw-bold">
            <span v-if="payment.customer"> - </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Validé à :</div>
          <div class="col p-1 fw-bold">
            <span v-if="payment.customer"> - </span>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="col-auto p-1">Remarque :</div>
    <div class="col p-1 fw-bold">
      {{ payment.remark }}
    </div>
    <br />

    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Facture Réf</th>
          <th scope="col">Date</th>
          <th scope="col">M. Facture</th>
          <th scope="col">M. Payé</th>
          <th scope="col">M. Impayé</th>
          <th scope="col">Créer par</th>
        </tr>
      </thead>
      <!-- {{ payment.payment_sales }} -->
      <tbody>
        <tr
          v-for="(paymentSale, index) in payment.payment_sales"
          :key="index++"
        >
          <td>
            {{ index }}
          </td>
          <td>
            {{ paymentSale.sale_reference }}
          </td>

          <td>{{ paymentSale.date }}</td>

          <td>
            <span v-if="paymentSale.sale">
              <span v-if="paymentSale.sale.totalPriceTTC >= 0">
                {{ paymentSale.sale.totalPriceTTC }}
              </span>
            </span>
          </td>

          <td class="text-success fw-bold">
            <span v-if="paymentSale.amount >= 0">
              {{ paymentSale.amount | toFixedWithSpace }}
            </span>
          </td>
          <td class="text-danger">
            <span v-if="paymentSale.remainingAmount">
              {{ paymentSale.remainingAmount | toFixedWithSpace }}
            </span>
          </td>
          <td>
            <span v-if="paymentSale.user">
              {{ paymentSale.user.name }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  data() {
    return {};
  },

  mounted() {
    this.$store.dispatch("payment/show", this.$route.params.reference);
  },
  computed: {
    ...mapGetters("payment", {
      payment: "getPayment",
    }),
    ...mapGetters("auth", {
      userRole: "getUserRole",
    }),
  },
  methods: {
    async refresh() {
      await this.$store.dispatch("payment/show", this.$route.params.reference);
    },

    async remove(data) {
      let payment = data;
      await this.$confirm({
        message:
          "Vous voulez le paiement  " +
          payment.reference +
          "  Montant de : " +
          payment.amount,
        button: {
          no: "Non",
          yes: "Oui",
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("payment/destroy", payment.reference);
          }
        },
      });
    },

    async print(reference) {
      await this.$store.dispatch("payment/printReceipt", reference);
    },
  },

  filters: {
    toFixedWithSpace: (value) => {
      return parseFloat(value)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    },
  },
};
</script>
